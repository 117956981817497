import loadable from '@loadable/component';
import { FullWidthField, ImageQuery } from '@rategravity/marketing-components';
import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Hero, HeroImage } from '../components/find-your-best-mortgage-mktg02/hero';
import { Layout } from '../components/layout';
import { breakpoints } from '../modules/breakpoints';
import { colors } from '../modules/colors';

const FindYourBestMortgageTorso = loadable(
  () => import('../components/find-your-best-mortgage-mktg02/torso'),
  {
    resolveComponent: (components) => components.FindYourBestMortgageTorso
  }
);

const CTA = loadable(() => import('../components/find-your-best-mortgage-mktg02/cta'), {
  resolveComponent: (components) => components.CTA
});

export const query = graphql`
  query {
    images: allFile(
      filter: {
        relativePath: { regex: "/find-your-best-mortgage-mktg02/" }
        extension: { ne: "svg" }
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

const FindYourBestMortgage = ({ data }: { data: ImageQuery }) => {
  return (
    <Layout>
      <Helmet>
        <style>{`
        body {
          background-color: ${colors.AQUA}
        }
        @media (min-width: ${breakpoints[2]}px) {
          nav {
            background-color: ${colors.AQUA};
          }
        }`}</style>
      </Helmet>
      <Hero>
        <HeroImage data={data} />
      </Hero>
      <FullWidthField backgroundColor={colors.WHITE} desktopMaxWidth={1280} mobileLateralGutter={0}>
        <FindYourBestMortgageTorso data={data} />
      </FullWidthField>
      <CTA />
    </Layout>
  );
};

export default FindYourBestMortgage;
